import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../templates/LayoutService";
import {
  blogHeader,
  blogPostListContainer,
  blogPostList,
} from "../styles/components/blog.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox } from "../styles/components/boxes.module.scss";
import "../styles/components/content.module.scss";
import { Link } from "gatsby";

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Party Boat Blog | Tampa Bay Boating Adventures</title>
        <meta
          name="description"
          content="Read the latest news and announcements about our private party boat charters in the Tampa Bay, Florida area and book yours today!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends partying on a boat with drinks in Tampa Bay, FL."
                  src="../images/blog/friends-partying-on-boat-with-drinks.jpg"
                />
              </div>
            </div>
            <div className={blogPostListContainer}>
              <div className="row center">
                <div className="col">
                  <h1>Party Boat Blog</h1>
                </div>
              </div>

              <ul className={blogPostList}>
              <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="captain crew bar island"
                      src="../images/blog/captain-crew-bar-island.jpg"
                      width="420px"
                    />
                  </div>
                  <h2>What Happened to Beer Can Island?</h2>
                  <div>
                    <span>March 09, 2024</span>
                  </div>
                  <Link to="/blog/what-happened-to-beer-can-island-pine-key">
                    View Article
                  </Link>
                </li>
                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="power boats racing near mangroves tampa"
                      src="../images/blog/power-boats-racing-near-mangroves-tampa.jpg"
                    />
                  </div>
                  <h2>What Size Party Boat Do I Need for a Group of Six?</h2>
                  <div>
                    <span>November 02, 2023</span>
                  </div>
                  <Link to="/blog/what-size-party-boat-need-for-group-of-six">
                    View Article
                  </Link>
                </li>
                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="pirate costume gasparilla near a boat"
                      src="../images/blog/pirate-costume-gasparilla-near-a-boat.jpg"
                    />
                  </div>
                  <h2>Renting a Party Boat for Gasparilla</h2>
                  <div>
                    <span>November 02, 2023</span>
                  </div>
                  <Link to="/blog/renting-party-boat-gasparilla">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="captain of boat cruise tip from customers"
                      src="../images/blog/captain-of-boat-cruise-tip-from-customers.jpg"
                    />
                  </div>
                  <h2>How Much Should I Tip a Boat Captain?</h2>
                  <div>
                    <span>November 02, 2023</span>
                  </div>
                  <Link to="/blog/how-much-should-tip-boat-captain">
                    View Article
                  </Link>
                </li>
                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="gasparilla pirate invasion downtown tampa"
                      src="../images/blog/gasparilla-pirate-invasion-downtown-tampa.jpg"
                    />
                  </div>
                  <h2>What is the Meaning of Gasparilla?</h2>
                  <div>
                    <span>October 22, 2023</span>
                  </div>
                  <Link to="/blog/what-is-the-meaning-of-gasparilla">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="cruising university of tampa"
                      src="../../images/blog/cruising-university-of-tampa.jpg"
                    />
                  </div>
                  <h2>Memorial Day 2023: Things To Do – Boat Version</h2>
                  <div>
                    <span>March 04, 2023</span>
                  </div>
                  <Link to="/blog/memorial-day-2023-things-to-do-boat-version">
                    View Article
                  </Link>
                </li>
                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Boats tied together"
                      src="../images/blog/boats-tied-together-beer-can-island.jpg"
                    />
                  </div>
                  <h2>Is Beer Can Island (Pine Key) Gone for Good?</h2>
                  <div>
                    <span>March 04, 2023</span>
                  </div>
                  <Link to="/blog/beer-can-island-pine-key-gone-for-good">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Bottle girl with guests on the charter"
                      src="../images/blog/bottlegirl-with-guests-on-charter.jpg"
                    />
                  </div>
                  <h2>
                    Discover the 5 Often-Forgotten Essentials for Your Summer
                    Party Boat Cruise
                  </h2>
                  <div>
                    <span>March 04, 2023</span>
                  </div>
                  <Link to="/blog/discover-5-often-forgotten-essentials-summer-party-boat-cruise">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Tampa bay red tide downtown"
                      src="../images/blog/tampa-bay-red-tide-downtown.jpg"
                    />
                  </div>
                  <h2>Unveiling Red Tide's Impact on Boating in Tampa Bay</h2>
                  <div>
                    <span>March 04, 2023</span>
                  </div>
                  <Link to="/blog/red-tide-impact-boating-tampa-bay">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Sunset with palm trees"
                      src="../images/blog/sunset-with-palm-trees.jpg"
                    />
                  </div>
                  <h2>
                    Explore Tampa Bay's Beauty: Top 3 Boating Views You Can't
                    Miss
                  </h2>
                  <div>
                    <span>November 12, 2022</span>
                  </div>
                  <Link to="/blog/top-3-boating-views-you-cant-miss">
                    View Article
                  </Link>
                </li>
                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Guy chilling on a boat with hot girls in Tampa Bay, FL."
                      src="../images/blog/guy-chilling-on-boat-with-hot-girls.jpg"
                    />
                  </div>
                  <h2>Is It Too Cold for a Charter Boat Party?</h2>
                  <div>
                    <span>December 10, 2022</span>
                  </div>
                  <Link to="/blog/is-it-too-cold-for-a-charter-boat-party">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Sunset over Hillsborough Bay in Florida."
                      src="../images/blog/sunset-over-hillsborough-bay-in-florida.jpg"
                    />
                  </div>
                  <h2>What is the Best Day of the Week for a Boat Party?</h2>
                  <div>
                    <span>November 16, 2022</span>
                  </div>
                  <Link to="/blog/what-is-the-best-day-of-the-week-for-a-boat-party">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Party boats tied together at Beer Can Island in Tampa Bay, FL."
                      src="../images/blog/boats-tied-together-at-beer-can-island-in-florida.jpg"
                    />
                  </div>
                  <h2>Top 3 Tampa Locations You Can ONLY Visit by Boat</h2>
                  <div>
                    <span>October 27, 2022</span>
                  </div>
                  <Link to="/blog/top-3-tampa-locations-you-can-only-visit-by-boat">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Boaters hanging out in the water around Beer Can Island."
                      src="../images/blog/beer-can-island-boaters-in-water.jpg"
                    />
                  </div>
                  <h2>The History of Beer Can Island (AKA - Pine Key)</h2>
                  <div>
                    <span>September 12, 2022</span>
                  </div>
                  <Link to="/blog/the-history-of-beer-can-island-aka-pine-key">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Hot girl and guy holding up drinks in Tampa Bay, FL."
                      src="../images/blog/hot-girl-and-guy-holding-up-drinks-in-tampa-bay.jpg"
                    />
                  </div>
                  <h2>
                    What Do I Need to Bring to a Party Boat Trip in Tampa?
                  </h2>
                  <div>
                    <span>August 30, 2022</span>
                  </div>
                  <Link to="/blog/what-do-i-need-to-bring-for-a-party-boat-trip-in-tampa">
                    View Article
                  </Link>
                </li>

                <li>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Group of friends partying on a boat in Tampa Bay, FL."
                      src="../images/blog/group-of-friends-hanging-out-on-party-boat-in-tampa-bay.jpg"
                    />
                  </div>
                  <h2>Welcome to Tampa Bay Boating Adventures</h2>
                  <div>
                    <span>August 5, 2022</span>
                  </div>
                  <Link to="/blog/welcome-to-tampa-bay-boating-adventures">
                    View Article
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default BlogPage;
